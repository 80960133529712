table {
  border-collapse: collapse;
  border-spacing: 0;
  color: #4a4a4d;
  font: 14px/1.4 "Helvetica Neue", Helvetica, Arial, sans-serif;
}

th,
td {
  padding: 10px 15px;
  vertical-align: middle;
}

thead {
  background: #fff;
  /* background: linear-gradient(#49708f, #293f50); */
  color: #395870;
  font-size: 11px;
  text-transform: uppercase;
}

th:first-child {
  border-top-left-radius: 5px;
  text-align: left;
}

th:last-child {
  border-top-right-radius: 5px;
}

tbody tr:nth-child(even) {
  background: #ffffff;
}

tbody tr:nth-child(odd) {
  background: #dae4eeb6;
}

.book-title {
  color: #395870;
  display: block;
}

.text-offset {
  color: #7c7c80;
  font-size: 12px;
}

.item-stock,
.item-qty {
  text-align: center;
}

.item-price {
  text-align: right;
}

.item-multiple {
  display: block;
}

tfoot {
  text-align: right;
}

tfoot tr:last-child {
  background: #f0f0f2;
  color: #395870;
  font-weight: bold;
}

tfoot tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}
tfoot tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}
